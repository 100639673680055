import React, { FC } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';
import { parseBoolean } from 'utils/parseHelpers';
import { scrollToTarget } from 'utils/browser';

import { IProductCardproperties } from './model';
import 'components/ProductCard/ProductCard.scss';
import 'components/ProductCard/ProductCardOverride.scss';

const ProductCard: FC<IProductCardproperties> = ({
  cardTitle,
  tickItems,
  featureItems,
  productImage,
  productImageAlt,
  currentFormat,
  link,
  tags = [],
  isPare,
  cartFakeLink,
  isFamily = '0',
  linkFamily,
  imageFamily,
  familyCardTitle,
  scrollTarget,
  hideVariant,
  hideFakeLink,
  className,
  cardTheme,
  disclaimer,
  productCardTitleTag,
}) => {
  const { pathname } = useLocation();
  const color = tags.find(tag => parseBoolean(tag.isProductFamily))?.color;
  const cx = classnames({
    'product-card': true,
    [`product-card--color-${color?.label}`]: color,
    [`${className}`]: className,
    [`product-card--${cardTheme}`]: cardTheme,
  });

  const image =
    parseBoolean(isFamily) && imageFamily?.fallbackUrl
      ? pathname === '/'
        ? imageFamily
        : productImage
      : productImage;
  const to = parseBoolean(isFamily) && linkFamily?.length ? linkFamily[0].url : link;
  const isShowFamilyCardTitle =
    parseBoolean(isFamily) && imageFamily?.fallbackUrl && familyCardTitle;
  const isVariantHidden = parseBoolean(hideVariant);

  const handleLinkClick = (e: React.MouseEvent) => {
    if (scrollTarget) {
      e.preventDefault();
      scrollToTarget(scrollTarget);
    }
  };

  return (
    <div className={cx} data-testid="product-card">
      {isPare ? <IconCustom icon="plus-circle" /> : null}
      <Link
        className="product-card__link"
        to={pathname === '/' ? to : link}
        onClick={handleLinkClick}
      >
        {image?.fallbackUrl ? (
          <div className="product-card__image">
            <GatsbyImage
              style={{ overflow: 'initial' }}
              objectFit="contain"
              objectPosition="50% 100%"
              className="product-card__image-img"
              fluid={image}
              alt={productImageAlt}
            />
          </div>
        ) : null}
        <div className="product-card__text">
          <div className="product-card__title-placeholder">
            <DangerouslySetInnerHtml
              element={productCardTitleTag || 'h3'}
              className="product-card__title"
              html={isShowFamilyCardTitle ? familyCardTitle : cardTitle}
            />
            {!isVariantHidden && currentFormat?.[0]?.properties?.label ? (
              <strong className="product-card__format">{currentFormat[0].properties.label}</strong>
            ) : null}
          </div>
          {tickItems?.length ? (
            <ul className="product-card__tick-list">
              {tickItems.map(({ value }) => (
                <li key={value} className="product-card__tick-list-item">
                  <IconCustom icon="tick" />
                  <DangerouslySetInnerHtml
                    element="div"
                    className="product-card__tick-list-label"
                    html={value}
                  />
                </li>
              ))}
            </ul>
          ) : null}
          {featureItems?.length ? (
            <ul className="product-card__features-list">
              {featureItems.map(({ properties: { icon, label } }) => (
                <li key={label} className="product-card__features-list-item">
                  {icon?.length ? <IconCustom icon={icon[0]} /> : null}
                  <DangerouslySetInnerHtml
                    element="div"
                    className="product-card__features-list-label"
                    html={label}
                  />
                </li>
              ))}
            </ul>
          ) : null}
          {disclaimer ? (
            <DangerouslySetInnerHtml className="product-card__disclaimer" html={disclaimer} />
          ) : null}
        </div>
        {cartFakeLink && !hideFakeLink ? (
          <h3 className="product-card__fake-link">
            {cartFakeLink}
            <IconCustom icon="chevron-right" />
          </h3>
        ) : null}
      </Link>
    </div>
  );
};

export default ProductCard;
